import { useQuery } from "@tanstack/react-query";
import { Button, Col, Modal, Row, Form, Dropdown } from "react-bootstrap";
import { useRecoilValue } from "recoil";
import { userState } from "../../../../../utils/atom";
import { getRequest, postRequest } from "../../../../../utils/apiRequest";
import { Loader } from "../../../../../components/loader";
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";

export const NewMemberCardModal = ({ showNewMemberCardModal, setShowNewMemberCardModal, refetch, membercard = null, setSelectedMemberCard }) => {
    const { token } = useRecoilValue(userState);
    const [partner, setPartner] = useState(null);
    const [subscription, setSubscription] = useState(null);
    const [file, setFile] = useState(null);
    const [bgColor1, setBgColor1] = useState("#03BFFF");
    const [bgColor2, setBgColor2] = useState("#9400D3");
    const [bgColor3, setBgColor3] = useState("#0000FF");
    const [hasBgColor1, setHasBgColor1] = useState(true);
    const [hasBgColor2, setHasBgColor2] = useState(true);
    const [hasBgColor3, setHasBgColor3] = useState(true);
    const [bgAngle, setBgAngle] = useState(180);
    const [bgSpeed, setBgSpeed] = useState(18);
    const [showToast, setShowToast] = useOutletContext();
    const [isLoading, setIsLoading] = useState(false);
    const [selectedSubModels, setSelectedSubModels] = useState([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const partnerQuery = useQuery({
        queryKey: ["partnerQuery"],
        queryFn: () => getRequest("/auth/partner/all", token),
    });
    const { data: partners } = partnerQuery;

    const subscriptionQuery = useQuery({
        queryKey: ["subscriptionQuery"],
        queryFn: () => getRequest("/auth/subscription/all-models", token),
    });
    const { data: subscriptions } = subscriptionQuery;

    useEffect(() => {
        if (partners && partners.length > 0) {
            setPartner(partners[0].id);
        }
    }, [partners]);

    useEffect(() => {
        if (membercard) {  
            // Editing an existing card → Load data
            setBgAngle(Number(membercard.bgAngle.replace('deg', '')));
            setBgColor1(membercard.bgColor1);
            setBgColor2(membercard.bgColor2);
            setBgColor3(membercard.bgColor3);
            setBgSpeed(Number(membercard.bgSpeed.replace('s', '')));
            setPartner(membercard.partner.id);
            setSelectedSubModels(membercard.subscriptionModels.map(model => model.id));
        } else {
            setBgAngle(180);
            setBgColor1("#03BFFF");
            setBgColor2("#9400D3");
            setBgColor3("#0000FF");
            setBgSpeed(18);
            setPartner(partners?.[0]?.id || null);
            setSelectedSubModels([]);
        }
    }, [membercard, partners]);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const angle = `${bgAngle}deg`;
            const speed = `${bgSpeed}s`;

            const payload = {
                partnerId: partner,
                subscriptionModels: selectedSubModels,
                bgColor1: bgColor1 ? bgColor1 : null,
                bgColor2: bgColor2 ? bgColor2 : null,
                bgColor3: bgColor3 ? bgColor3 : null,
                bgAngle: angle,
                bgSpeed: speed,
            };

            let response;
            if (membercard === null) {
                response = await postRequest("/auth/membercard/new", token, payload);
            }
            else {
                response = await postRequest("/auth/membercard/update", token, {...payload, id: membercard.id});
            }

            if (response.error) {
                setShowToast({
                    toastStatus: true,
                    toastMessage: response.error,
                    toastAppearance: "danger",
                });
                setIsLoading(false);
                setShowNewMemberCardModal(false);
                return;
            }

            const memberCardId = response.id;

            if (!memberCardId) {
                console.error("Failed to create memberCard");
                return;
            }

            // 2. Upload the image to S3 and update the memberCard with the image URL
            if (file) {
                const formData = new FormData();
                formData.append("id", memberCardId);
                formData.append("partner", partner);
                formData.append("file", file);

                const response = await postRequest("/auth/membercard/upload", token, formData, true);

                if (response.error) {
                    setShowToast({
                        toastStatus: true,
                        toastMessage: response.error,
                        toastAppearance: "danger",
                    });
                } else {
                    setShowToast({
                        toastStatus: true,
                        toastMessage: membercard ? "Mitgliedskarte erfolgreich aktualisiert. 🥳" : "Mitgliedskarte erfolgreich angelegt. 🥳",
                        toastAppearance: "success",
                    });
                    refetch();
                    setShowNewMemberCardModal(false);
                }
            }
            else {
                setShowToast({
                    toastStatus: true,
                    toastMessage: membercard ? "Mitgliedskarte erfolgreich aktualisiert. 🥳" : "Mitgliedskarte erfolgreich angelegt. 🥳",
                    toastAppearance: "success",
                });
                refetch();
                setShowNewMemberCardModal(false);
            }
        } catch (error) {
            console.error("Error creating memberCard:", error);
        }

        setIsLoading(false);
    };

    const handleSelectionChange = (subscriptionId) => {
        setSelectedSubModels(
            (prevSelected) =>
                prevSelected.includes(subscriptionId)
                    ? prevSelected.filter((id) => id !== subscriptionId) // Deselect
                    : [...prevSelected, subscriptionId] // Select
        );
    };

    if (
        partnerQuery.isLoading ||
        partnerQuery.isFetching ||
        subscriptionQuery.isLoading ||
        subscriptionQuery.isFetching ||
        isLoading
    ) {
        return <Loader isActive={true} />;
    }

    return (
        <Modal show={showNewMemberCardModal} onHide={() => {setSelectedMemberCard(null); setShowNewMemberCardModal(false)}} centered>
            <Form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>{`${membercard ? 'Membercard bearbeiten' : 'Neue Membercard erstellen'}`}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Row>
                        <Col xs={6} className="d-flex align-items-center">
                            Partner:
                        </Col>
                        <Col xs={6} className="text-end">
                            <Form.Select value={partner} onChange={(e) => setPartner(e.target.value)}>
                                {partners.map((partner) => {
                                    return (
                                        <option key={partner.id} value={partner.id}>
                                            {partner.name}
                                        </option>
                                    );
                                })}
                            </Form.Select>
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <Col xs={6}>Abomodell:</Col>
                        <Col xs={6} className="text-end">
                            <Dropdown className="w-100" show={isDropdownOpen} onToggle={(isOpen) => setIsDropdownOpen(isOpen)}>
                                <Dropdown.Toggle variant="primary">
                                    {selectedSubModels.length > 0
                                        ? `${selectedSubModels.length} ausgewählt`
                                        : "Abomodelle"}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Form className="px-3">
                                        {subscriptions.map((subscription) => (
                                            <Form.Check
                                                key={subscription.id}
                                                type="checkbox"
                                                id={`sub-${subscription.id}`}
                                                label={subscription.title}
                                                checked={selectedSubModels.includes(subscription.id)}
                                                onChange={() => handleSelectionChange(subscription.id)}
                                            />
                                        ))}
                                    </Form>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <Col xs={6} className="d-flex align-items-center">
                            Kartendesign (.png):
                        </Col>
                        <Col xs={6} className="text-end">
                            <Form.Control 
                                type="file" 
                                accept={[".png"]} 
                                onChange={handleFileChange} 
                                required={membercard ? false : true}
                            />
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <Col xs={6} className="d-flex align-items-center">
                            Hintergrundfarbe 1:
                        </Col>
                        <Col xs={3} className="text-end">
                            <Form.Control
                                type="color"
                                value={bgColor1}
                                onChange={(e) => setBgColor1(e.target.value)}
                                required
                            />
                        </Col>
                        <Col xs={3} className="d-flex align-items-center">
                            <Form.Check
                                type="switch"
                                checked={hasBgColor1}
                                onChange={() => {
                                    setHasBgColor1((prev) => {
                                        const newState = !prev;
                                        setBgColor1(newState ? "#0000FF" : "");
                                        return newState;
                                    });
                                }}
                            />
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <Col xs={6} className="d-flex align-items-center">
                            Hintergrundfarbe 2:
                        </Col>
                        <Col xs={3} className="text-end">
                            <Form.Control
                                type="color"
                                value={bgColor2}
                                onChange={(e) => setBgColor2(e.target.value)}
                                required
                            />
                        </Col>
                        <Col xs={3} className="d-flex align-items-center">
                            <Form.Check
                                type="switch"
                                checked={hasBgColor2}
                                onChange={() => {
                                    setHasBgColor2((prev) => {
                                        const newState = !prev;
                                        setBgColor2(newState ? "#0000FF" : "");
                                        return newState;
                                    });
                                }}
                            />
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <Col xs={6} className="d-flex align-items-center">
                            Hintergrundfarbe 3:
                        </Col>
                        <Col xs={3} className="text-end">
                            <Form.Control
                                type="color"
                                value={bgColor3}
                                onChange={(e) => setBgColor3(e.target.value)}
                                disabled={!hasBgColor3}
                            />
                        </Col>
                        <Col xs={3} className="d-flex align-items-center">
                            <Form.Check
                                type="switch"
                                checked={hasBgColor3}
                                onChange={() => {
                                    setHasBgColor3((prev) => {
                                        const newState = !prev;
                                        setBgColor3(newState ? "#0000FF" : "");
                                        return newState;
                                    });
                                }}
                            />
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <Col xs={6} className="d-flex align-items-center">
                            Hintergrundwinkel:
                        </Col>
                        <Col xs={3} className="text-end">
                            <Form.Range
                                min={0}
                                max={360}
                                step={5}
                                value={bgAngle}
                                onChange={(e) => setBgAngle(e.target.value)}
                            />
                        </Col>
                        <Col xs={3}>{bgAngle}°</Col>
                    </Row>

                    <Row className="mt-3">
                        <Col xs={6} className="d-flex align-items-center">
                            Hintergrund-Tempo:
                        </Col>
                        <Col xs={3} className="text-end">
                            <Form.Range
                                min={0}
                                max={30}
                                step={1}
                                value={bgSpeed}
                                onChange={(e) => setBgSpeed(e.target.value)}
                            />
                        </Col>
                        <Col xs={3}>{bgSpeed}s</Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => setShowNewMemberCardModal(false)}>
                        Abbrechen
                    </Button>
                    <Form.Control className="btn btn-success w-25" type="submit" value={"Speichern"}></Form.Control>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};
