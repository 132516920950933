import { Button, Card, Col, Container, Row } from "react-bootstrap"
import { Loader } from "../../../components/loader"
import { TitleWithBackButton } from "../../../components/title-with-back-button/titleWithBackButton"
import { useRecoilValue } from "recoil";
import { userState } from "../../../utils/atom";
import { useQuery } from "@tanstack/react-query";
import { getRequest, postRequest } from "../../../utils/apiRequest";
import { useNavigate, useOutletContext } from "react-router-dom";
import { formatDateTime, formatDateTimeToDDMMYY } from "../../../utils/formatDateTime";

export const WaitingRoomUser = () => {

    const [showToast, setShowToast] = useOutletContext();
    const navigate = useNavigate();
    const { token } = useRecoilValue(userState);
    const waitingRoomQuery = useQuery({
        queryKey: ['waiting-room-query'],
        queryFn: () => getRequest(`/auth/waiting-rooms-by-user`, token)
    });
    const { data } = waitingRoomQuery;

    const handleDelete = async (e, childId, courseId) => {
        e.stopPropagation();
        console.log(childId, courseId);
        const data = {
            childId: childId,
            courseId: courseId
        }

        const response = await postRequest('/auth/remove-from-waitingroom', token, data);
        if (response.error) {
            setShowToast({
                toastStatus: true,
                toastMessage: response.error,
                toastAppearance: 'danger'
            });
        }
        else {
            setShowToast({
                toastStatus: true,
                toastMessage: 'Kind wurde erfolgreich von der Warteliste entfernt.',
                toastAppearance: 'success'
            });
            waitingRoomQuery.refetch();
        }
    };

    return (
        <Loader isActive={waitingRoomQuery.isLoading || waitingRoomQuery.isFetching}>
            <Container fluid>
                <TitleWithBackButton title={'Deine Wartelisten'} />
            </Container>
            {(data && Object.keys(data).length > 0) &&
                <Container>
                    {Object.keys(data).map((child) => {
                        if (data[child].length > 0) {
                            console.log(child);
                            return (
                                <Row key={child.id}>
                                    <Col xs={12} className="mt-3">
                                        <Card className="mt-3">
                                            <Card.Header>
                                                {child}
                                            </Card.Header>
                                            <Card.Body className="p-0 overflow-hidden no-border-on-last-child">
                                                {data[child].map((course) => {
                                                    return (
                                                        <Row 
                                                            className="p-3 border-bottom hoverable-title align-items-center"
                                                            onClick={() => navigate(`/course/${course.id}`)}
                                                            >
                                                            <Col xs={4}>
                                                                {course.title}
                                                            </Col>
                                                            <Col xs={4} className="text-center">
                                                                {formatDateTimeToDDMMYY(course.date)}
                                                            </Col>
                                                            <Col xs={4} className="text-end">
                                                                <Button  
                                                                    variant="danger"
                                                                    onClick={(e) => handleDelete(e, course.childId, course.id)}
                                                                >
                                                                    Abmelden
                                                            </Button>
                                                            </Col>
                                                        </Row>
                                                    )
                                                })}
                                            </Card.Body>
                                        </Card>                                    
                                    </Col>
                                </Row>                        
                            )
                        }
                    })}
                </Container>
            }
        </Loader>
    )
}