import { useQuery } from "@tanstack/react-query";
import { Button, Card, Col, Modal, Row } from "react-bootstrap"
import { getRequest } from "../../../../utils/apiRequest";
import { Loader } from "../../../../components/loader";
import { Link } from "react-router-dom";

export const PartnerBenefitsModal = ({ showPartnerBenefitsModal, setShowPartnerBenefitsModal, token }) => {

    const partnerQuery = useQuery({
        queryKey: ["partner-query"],
        queryFn: () => getRequest(`/auth/partner/all`, token),
    });
    const { data: partner } = partnerQuery;

    return (
        <Loader isActive={partnerQuery.isLoading || partnerQuery.isFetching}>
            {partner &&
                <Modal
                    show={showPartnerBenefitsModal}
                    onHide={() => setShowPartnerBenefitsModal(false)}
                    centered
                    size="lg"
                >
                    <Modal.Header closeButton>Unser Partner</Modal.Header>
                    <Modal.Body>
                        <Row>
                            {partner.map((partnerElement) => {
                                return (
                                    <Col xs={12} md={6} key={partnerElement.id} className="mb-3">
                                        <Card className="h-100">
                                            <Card.Header>{partnerElement.name}</Card.Header>
                                            <Card.Body>
                                                <Row>
                                                    <Col xs={12}>
                                                        <Card.Title>Beschreibung:</Card.Title>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12}>
                                                        <Card.Text>{partnerElement.description}</Card.Text>
                                                    </Col>
                                                </Row>
                                                <Row className="mt-3 border-top pt-3">
                                                    <Col xs={12}>
                                                        <Card.Title>✨ Vorteile:</Card.Title>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12}>
                                                        <Card.Text>{partnerElement.benefits}</Card.Text>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                            <Card.Footer className="p-3">
                                                <Link to={`https://${partnerElement.url}`} className="btn btn-primary w-100" target="_blank">Zur Partner-Website</Link>
                                            </Card.Footer>
                                        </Card>
                                    </Col>
                                )
                            })}
                        </Row>
                    </Modal.Body>
                </Modal>
            }
        </Loader>
    )
}