import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom"
import { getRequest } from "../../../utils/apiRequest";
import { Loader } from "../../../components/loader";
import { Card, Col, Container, Row } from "react-bootstrap";
import { formatDateTimeToDDMMYYYY } from "../../../utils/formatDateTime";

export const MemberCardVerify = () => {
    
    const { id } = useParams();

    const memberCardQuery = useQuery({
        queryKey: ["member-card-verify"],
        queryFn: () => getRequest(`/membercard/verify/${id}`),
    });

    const { data: subscription } = memberCardQuery;
    
    if (memberCardQuery.isLoading || memberCardQuery.isFetching) {
        return ( <Loader isActive={true} />)
    }

    return (
        <>
            {subscription && (
                <Container className="pt-3 pb-3 d-flex justify-content-center align-items-center" style={{height: '100vh', width: '100vw'}}>
                    <Row className="w-100">
                        <Col xs={12}>
                            <Card className={`${subscription.isActive ? 'border-success' : 'border-danger'} w-100`}>
                                <Card.Header>
                                    {subscription.user.memberCard.partner.name} Membercard
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col xs={6}>
                                            Status:
                                        </Col>
                                        <Col xs={6} className="text-end">
                                            {subscription.isActive 
                                                ? <p className="m-0 text-success">Gültig</p>
                                                : <p className="m-0 text-danger">Abgelaufen</p>
                                            }
                                        </Col>
                                    </Row>

                                    <Row className="mt-3">
                                        <Col xs={6}>
                                            Gültig bis:
                                        </Col>
                                        <Col xs={6} className="text-end">
                                            {formatDateTimeToDDMMYYYY(subscription.validUntil)}
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            )}
        </>
    )
}