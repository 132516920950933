import { useQuery } from "@tanstack/react-query";
import { Button, Col, Form, Modal, Row } from "react-bootstrap"
import { getRequest, postRequest } from "../../../../utils/apiRequest";
import { Loader } from "../../../../components/loader";
import { formatDateTimeToDDMMYYYY } from "../../../../utils/formatDateTime";
import { addMonths } from 'date-fns';
import { useOutletContext } from "react-router-dom";
import { useState } from "react";

export const ConfirmSubscriptionModal = ({ showConfirmSubscriptionbModal, setShowConfirmSubscriptionModal, subscriptionId, userId, token, refetch }) => {

    const [showToast, setShowToast] = useOutletContext();
    const [isLoading, setIsLoading] = useState(false);
    const [selectedPartnerId, setSelectedPartnerId] = useState(0);

    const subscriptionDetailQuery = useQuery({
        queryKey: ["user-subscription-detail-query"],
        queryFn: () => getRequest(`/auth/user/subscription/${subscriptionId}`, token),
    });
    const { data: subscription } = subscriptionDetailQuery;

    const handleSubmit = async () => {
        const payload = {
            userId: userId,
            hasAutoRenew: true,
            partnerId: selectedPartnerId,
            ...subscription
        }
        const response = await postRequest('/auth/subscription/add', token, payload);
        if (response.error) {
            setShowToast({
                toastStatus: true,
                toastMessage: response.error,
                toastAppearance: 'danger' 
            });
            setIsLoading(false);
        }
        else {
            setShowToast({
                toastStatus: true,
                toastMessage: `Dein Abonnement wurde erfolgreich abgeschlossen! 🥳`,
                toastAppearance: 'success' 
            });
            setIsLoading(false);
            setShowConfirmSubscriptionModal(false);
            refetch();
        }
    }

    return (
        <Loader isActive={subscriptionDetailQuery.isLoading || subscriptionDetailQuery.isFetching || isLoading}>
            {subscription &&
                <Modal
                    centered
                    size="md"
                    show={showConfirmSubscriptionbModal}
                    onHide={() => setShowConfirmSubscriptionModal(false)}
                >
                    <Modal.Header>{subscription.title}</Modal.Header>
                    <Modal.Body>
                        <Row className="pb-3">
                            <Col xs={12}>
                                {subscription.description}
                            </Col>
                        </Row>
                        <Row className="border-top pt-3">
                            <Col xs={6}>
                                Abostart:
                            </Col>
                            <Col xs={6} className="text-end">
                                {formatDateTimeToDDMMYYYY(new Date())}
                            </Col>
                        </Row>
                        
                        <Row>
                            <Col xs={6}>
                                Aboende:
                            </Col>
                            <Col xs={6} className="text-end">
                                {formatDateTimeToDDMMYYYY(addMonths(new Date(), subscription.durationMonths))}
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={6}>
                                Preis:
                            </Col>
                            <Col xs={6} className="text-end">
                                {Number(subscription.costPerPeriod).toFixed(2)} €
                            </Col>
                        </Row>

                        <Row className="pb-3">
                            <Col xs={6}>
                                Enthaltene MwSt.:
                            </Col>
                            <Col xs={6} className="text-end">
                                {(subscription.costPerPeriod / 119 * 19).toFixed(2).toString().replace('.', ',')} €
                            </Col>
                        </Row>

                        <Row className="border-top pt-3">
                            <Col xs={6}>Wurde dir die Membercard von einem unserer Partner empfohlen?</Col>
                            <Col xs={6}>
                                <Form.Select
                                    value={selectedPartnerId}
                                    onChange={(e) => setSelectedPartnerId(e.target.value)}
                                >
                                    <option key={0} value={0}>Nein</option>
                                    {subscription.memberCards.map((memberCard) => {
                                        return (
                                            <option 
                                                key={memberCard.partner.id} 
                                                value={memberCard.partner.id}
                                            >
                                                {memberCard.partner.name}
                                            </option>
                                        )
                                    })}
                                </Form.Select>
                            </Col>
                        </Row>

                    </Modal.Body>
                    <Modal.Footer className="p-3">
                        <Button className="btn btn-danger" onClick={() => setShowConfirmSubscriptionModal(false)}>Abbrechen</Button>
                        <Button className="btn btn-success" onClick={handleSubmit}>Abonnement kostenpflichtig abschließen</Button>
                    </Modal.Footer>
                </Modal>
            }
        </Loader>
    )
}