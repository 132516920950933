import { useQuery } from "@tanstack/react-query";
import { Button, Card, Modal, Row, Col } from "react-bootstrap";
import { getRequest } from "../../../../utils/apiRequest";
import { useEffect, useState } from "react";
import { Loader } from "../../../../components/loader";
import { Link } from "react-router-dom";
import QRCode from 'react-qr-code';

export const MemberCardModal = ({ showMemberCardModal, setShowMemberCardModal, token, memberCardId, subscriptionId }) => {
    const [colors, setColors] = useState([]);
    const [showQR, setShowQR] = useState(false);

    const memberCardQuery = useQuery({
        queryKey: ["member-card-query"],
        queryFn: () => getRequest(`/auth/membercard/${memberCardId}`, token),
    });
    const { data: memberCard } = memberCardQuery;

    useEffect(() => {
        if (memberCard) {
            setColors([memberCard.bgColor1, memberCard.bgColor2, memberCard.bgColor3].filter(Boolean));
        }
    }, [memberCard]);

    return (
        <Loader isActive={memberCardQuery.isLoading || memberCardQuery.isFetching}>
            {memberCard && (
                <Modal show={showMemberCardModal} onHide={() => setShowMemberCardModal(false)} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Meine Mitgliedskarte</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Card style={{position: 'relative'}}>
                            <div
                                style={{
                                    borderRadius: "5px",
                                    background: `${
                                        colors.length > 1
                                            ? `linear-gradient(${memberCard.bgAngle}, ${colors.join(", ")})`
                                            : `${colors[0]}`
                                    }`,
                                    animation: `gradient-animation ${memberCard.bgSpeed} ease infinite`,
                                    backgroundSize: `180% 180%`,
                                }}
                            >
                                {memberCard.imageUrl.endsWith(".png") && (
                                    <Card.Img src={memberCard.imageUrl}></Card.Img>
                                )}
                                {memberCard.imageUrl.endsWith(".mp4") && (
                                    <video autoPlay loop>
                                        <source src={memberCard.imageUrl} type="video/mp4"></source>
                                    </video>
                                )}
                                {showQR &&
                                    <Row style={{position: 'absolute', top: '35%', left: '22%'}}>
                                        <Col xs={12} className="p-3" style={{ background: 'white' }}>
                                            <QRCode 
                                                value={`https://app.kiddiefy.de/membercard/verify/${subscriptionId}`}
                                            />
                                        </Col>
                                    </Row>
                                }
                            </div>
                        </Card>
                    </Modal.Body>
                    <Modal.Footer className="p-3">
                        <Button className="w-100" onClick={() => setShowQR(prev => prev == true ? false : true)}>{showQR ? 'QR-Code ausblenden' : 'QR-Code anzeigen 👀'}</Button>
                    </Modal.Footer>
                </Modal>
            )}
        </Loader>
    );
};
