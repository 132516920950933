import { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Loader } from "../../../../../components/loader";
import { postRequest } from "../../../../../utils/apiRequest";
import { useOutletContext } from "react-router-dom";

export const NewPartnerModal = ({ showNewPartnerModal, setShowNewPartnerModal, token, refetch, partner = null, setSelectedPartner }) => {
    const [payload, setPayload] = useState({
        name: "",
        email: "",
        phone: "",
        address: "",
        city: "",
        postal: "",
        description: "",
        benefits: "",
        url: "",
    });

    useEffect(() => {
        if (partner !== null) {
            setPayload({
                name: partner.name,
                email: partner.email,
                phone: partner.phone,
                address: partner.address,
                city: partner.city,
                postal: partner.postal,
                description: partner.description,
                benefits: partner.benefits,
                url: partner.url,
            });
        } else {
            setPayload({
                name: "",
                email: "",
                phone: "",
                address: "",
                city: "",
                postal: "",
                description: "",
                benefits: "",
                url: "",
            });
        }
    }, [partner]);

    const [isLoading, setIsLoading] = useState(false);
    const [showToast, setShowToast] = useOutletContext();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPayload((prevPayload) => ({
            ...prevPayload,
            [name]: value,
        }));
    };

    const handleSubmit = async () => {
        setIsLoading(true);

        let response;

        if (partner == null) {
            response = await postRequest("/auth/partner/new", token, payload);
        }
        else {
            response = await postRequest("/auth/partner/update", token, { ...payload, id: partner.id });
        }
        
        if (response.error) {
            setShowToast({
                toastStatus: true,
                toastMessage: response.error,
                toastAppearance: "danger",
            });
        } else {
            setShowToast({
                toastStatus: true,
                toastMessage: partner ? "Partner erfolgreich aktualisiert! 🥳" : "Partner erfolgreich angelegt! 🥳",
                toastAppearance: "success",
            });
        }
        refetch();
        setShowNewPartnerModal(false);
        setIsLoading(false);
    };

    if (isLoading) {
        return <Loader isActive={isLoading} />;
    }

    return (
        <Modal show={showNewPartnerModal} onHide={() => { setShowNewPartnerModal(false); setSelectedPartner(null); }} centered>
            <Modal.Header closeButton>
                <Modal.Title>Neuen Partner anlegen</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit}>
                <Modal.Body>
                    <Row className="mt-3">
                        <Col xs={6}>Name:</Col>
                        <Col xs={6}>
                            <Form.Control
                                type="text"
                                name="name"
                                value={payload.name}
                                onChange={(e) => handleChange(e)}
                                required
                            ></Form.Control>
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <Col xs={6}>E-Mail:</Col>
                        <Col xs={6}>
                            <Form.Control
                                type="E-Mail"
                                name="email"
                                value={payload.email}
                                onChange={(e) => handleChange(e)}
                            ></Form.Control>
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <Col xs={6}>Telefon:</Col>
                        <Col xs={6}>
                            <Form.Control
                                type="text"
                                name="phone"
                                value={payload.phone}
                                onChange={(e) => handleChange(e)}
                            ></Form.Control>
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <Col xs={6}>Straße & Hausnummer:</Col>
                        <Col xs={6}>
                            <Form.Control
                                type="text"
                                name="address"
                                value={payload.address}
                                onChange={(e) => handleChange(e)}
                            ></Form.Control>
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <Col xs={6}>Postleitzahl:</Col>
                        <Col xs={6}>
                            <Form.Control
                                type="text"
                                name="postal"
                                value={payload.postal}
                                onChange={(e) => handleChange(e)}
                            ></Form.Control>
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <Col xs={6}>Stadt:</Col>
                        <Col xs={6}>
                            <Form.Control
                                type="text"
                                name="city"
                                value={payload.city}
                                onChange={(e) => handleChange(e)}
                            ></Form.Control>
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <Col xs={6}>Website:</Col>
                        <Col xs={6}>
                            <Form.Control
                                type="text"
                                name="url"
                                value={payload.url}
                                onChange={(e) => handleChange(e)}
                                placeholder={`Link ohne "https://" eingeben!`}
                            ></Form.Control>
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <Col xs={6}>Beschreibung:</Col>
                        <Col xs={6}>
                            <Form.Control
                                as="textarea"
                                rows={5}
                                name="description"
                                value={payload.description}
                                onChange={(e) => handleChange(e)}
                            ></Form.Control>
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <Col xs={6}>Vorteile:</Col>
                        <Col xs={6}>
                            <Form.Control
                                as="textarea"
                                rows={5}
                                name="benefits"
                                value={payload.benefits}
                                onChange={(e) => handleChange(e)}
                            ></Form.Control>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-danger" onClick={() => setShowNewPartnerModal(false)}>
                        Abbrechen
                    </Button>
                    <Form.Control type="submit" className="btn btn-primary w-25" value={"Speichern"}></Form.Control>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};
