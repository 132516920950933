import { Alert, Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import { Loader } from "../../../components/loader";
import { TitleWithBackButton } from "../../../components/title-with-back-button/titleWithBackButton";
import { useRecoilValue } from "recoil";
import { userState } from "../../../utils/atom";
import { useQuery } from "@tanstack/react-query";
import { getRequest } from "../../../utils/apiRequest";
import { useIsMobile } from "../../../utils/hooks/isMobile";
import { useState } from "react";
import { NewMemberCardModal } from "./modals/new-membercard-model/NewMemberCardModal";
import { NewSubModelModal } from "../../subscriptions/modals/newSubModelModal";
import { useNavigate } from "react-router-dom";
import "./member-card-admin.scss";
import { NewPartnerModal } from "./modals/new-partner-modal/NewPartnerModal";
import { PencilSquare, TrashFill } from "react-bootstrap-icons";
import { DeleteConfirmModal } from "./modals/delete-confirm-modal/DeleteConfirmModal";
import { MemberCardDetailsModal } from "./modals/membercard-details-modal/MemberCardDetailsModal";

export const MemberCardsAdmin = () => {
    const { token } = useRecoilValue(userState);
    const isMobile = useIsMobile();

    const [showNewMemberCardModal, setShowNewMemberCardModal] = useState(false);
    const [showNewSubModelModal, setShowNewSubModelModal] = useState(false);
    const [showNewPartnerModal, setShowNewPartnerModal] = useState(false);
    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
    const [showMemberCardDetailsModal, setShowMemberCardDetailsModal] = useState(false);
    const [selectedMemberCard, setSelectedMemberCard] = useState(null);
    const [selectedPartner, setSelectedPartner] = useState(null);
    const navigate = useNavigate();

    const memberCardsQuery = useQuery({
        queryKey: ["memberCardsQuery"],
        queryFn: () => getRequest("/auth/membercard/all", token),
    });
    const { data: memberCardData } = memberCardsQuery;

    const handleDeleteClick = (memberCard) => {
        setSelectedMemberCard(memberCard); // Set the selected card for deletion
        setShowDeleteConfirmModal(true); // Show the modal
    };

    return (
        <Loader isActive={memberCardsQuery.isLoading || memberCardsQuery.isFetching}>
            <Container fluid>
                <TitleWithBackButton title={"Mitgliedskartenverwaltung"} />
            </Container>
            {memberCardData && (
                <Container className="mt-3">
                    <Row className=" mb-3">
                        <Col xs={12} className="ps-3 pe-3 d-flex align-items-center justify-content-center">
                            <Row className="border w-100 p-3 rounded bg-dark-subtle">
                                <Col xs={12} md={4}>
                                    <Button
                                        className="h-100 w-100"
                                        onClick={() => {
                                            setSelectedMemberCard(null);
                                            setShowNewMemberCardModal(true);
                                        }}
                                    >
                                        Neue Mitgliedskarte erstellen
                                    </Button>
                                </Col>
                                <Col xs={12} md={4} className={isMobile ? "mt-3 mb-3" : ""}>
                                    <Button className="h-100 w-100" onClick={() => setShowNewPartnerModal(true)}>
                                        Neuen Partner anlegen
                                    </Button>
                                </Col>
                                <Col xs={12} md={4}>
                                    <Button className="h-100 w-100" onClick={() => setShowNewSubModelModal(true)}>
                                        Neues Abonnement anlegen
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {memberCardData.length === 0 && (
                        <Row>
                            <Col xs={12}>
                                <Alert variant="info" className="text-center">
                                    Noch keine Membercards vorhanden.
                                </Alert>
                            </Col>
                        </Row>
                    )}
                    {memberCardData.length > 0 && (
                        <Row>
                            {memberCardData.map((memberCard) => {
                                const colors = [memberCard.bgColor1, memberCard.bgColor2, memberCard.bgColor3].filter(
                                    Boolean
                                );

                                return (
                                    <Col xs={12} md={6} lg={4} key={memberCard.id}>
                                        <Card>
                                            <Row>
                                                <Col xs={12} className="d-flex gap-3 justify-content-end pe-4 pt-3">
                                                    <PencilSquare
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            setSelectedMemberCard(memberCard);
                                                            setShowNewMemberCardModal(true);
                                                        }}
                                                    />
                                                    <TrashFill
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => handleDeleteClick(memberCard)}
                                                    />
                                                </Col>
                                            </Row>

                                            <div className="ps-3 pe-3 pt-3">
                                                <div
                                                    style={{
                                                        borderRadius: "5px",
                                                        background: `${
                                                            colors.length > 1
                                                                ? `linear-gradient(${memberCard.bgAngle}, ${colors.join(
                                                                      ", "
                                                                  )})`
                                                                : `${colors[0]}`
                                                        }`,
                                                        animation: `gradient-animation ${memberCard.bgSpeed} ease infinite`,
                                                        backgroundSize: `180% 180%`,
                                                    }}
                                                >
                                                    {memberCard.imageUrl.endsWith(".png") && (
                                                        <Card.Img src={memberCard.imageUrl}></Card.Img>
                                                    )}
                                                    {memberCard.imageUrl.endsWith(".mp4") && (
                                                        <video autoPlay loop>
                                                            <source src={memberCard.imageUrl} type="video/mp4"></source>
                                                        </video>
                                                    )}
                                                </div>
                                            </div>

                                            <Card.Body>
                                                <Card.Title>{`${memberCard.partner.name} Mitgliedskarte`}</Card.Title>
                                                <Row className="mt-3">
                                                    <Col xs={12}>
                                                        <Button
                                                            className="w-100"
                                                            onClick={() => {setSelectedMemberCard(memberCard); setShowMemberCardDetailsModal(true)}}
                                                        >
                                                            Details
                                                        </Button>
                                                    </Col>
                                                    <Col xs={12} className="mt-3">
                                                        <Button
                                                            className="w-100 btn btn-warning"
                                                            onClick={() =>{setSelectedPartner(memberCard.partner); setShowNewPartnerModal(true)}}
                                                        >
                                                            Partner bearbeiten
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>

                                        <DeleteConfirmModal
                                            showDeleteConfirmModal={showDeleteConfirmModal}
                                            setShowDeleteConfirmModal={setShowDeleteConfirmModal}
                                            membercard={selectedMemberCard ? selectedMemberCard : memberCard}
                                            token={token}
                                            refetch={memberCardsQuery.refetch}
                                        />
                                    </Col>
                                );
                            })}
                        </Row>
                    )}
                </Container>
            )}

            <NewMemberCardModal
                showNewMemberCardModal={showNewMemberCardModal}
                setShowNewMemberCardModal={setShowNewMemberCardModal}
                refetch={memberCardsQuery.refetch}
                membercard={selectedMemberCard ? selectedMemberCard : null}
                setSelectedMemberCard={setSelectedMemberCard}
            />

            <NewSubModelModal
                showNewSubModelModal={showNewSubModelModal}
                handleCloseNewSubModal={() => setShowNewSubModelModal(false)}
                token={token}
                refetch={memberCardsQuery.refetch}
            />

            <NewPartnerModal
                showNewPartnerModal={showNewPartnerModal}
                setShowNewPartnerModal={setShowNewPartnerModal}
                token={token}
                refetch={memberCardsQuery.refetch}
                partner={selectedPartner ? selectedPartner : null}
                setSelectedPartner={setSelectedPartner}
            />

            {selectedMemberCard &&
                <MemberCardDetailsModal 
                    showMemberCardDetailsModal={showMemberCardDetailsModal}
                    setShowMemberCardDetailsModal={setShowMemberCardDetailsModal}
                    memberCardId={selectedMemberCard.id}
                />
            }
        </Loader>
    );
};
