import { useQuery } from "@tanstack/react-query";
import { Col, Modal, Row, Stack } from "react-bootstrap";
import { getRequest } from "../../../../../utils/apiRequest";
import { useRecoilValue } from "recoil";
import { userState } from "../../../../../utils/atom";
import { Link } from "react-router-dom";

export const MemberCardDetailsModal = ({ showMemberCardDetailsModal, setShowMemberCardDetailsModal, memberCardId = null}) => {
    const { token } = useRecoilValue(userState);

    const memberCardQuery = useQuery({
        queryKey: ["memberCardQuery", memberCardId],
        queryFn: () => getRequest(`/auth/membercard/${memberCardId}`, token),
        enabled: memberCardId !== null,
    });
    const { data: memberCard } = memberCardQuery;

    if (!memberCard || memberCardQuery.isLoading || memberCardQuery.isFetching) {
        <Modal>
            <p>Keine Membercard ausgewählt.</p>
        </Modal>;
    }

    return (
        <>
            {memberCard && (
                <Modal
                    size="lg"
                    show={showMemberCardDetailsModal}
                    onHide={() => setShowMemberCardDetailsModal(false)}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{memberCard.partner.name} Membercard</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col xs={6}>Verknüpfte Abonnements:</Col>
                            <Col xs={6} className="text-end">
                                <Stack>
                                    {memberCard.subscriptionModels.map((model) => {
                                        return (
                                            <Link to={`/subscription/${model.id}`}>{model.title}</Link>
                                        )
                                    })}
                                </Stack>
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col xs={6}>Aktive Abonnenten:</Col>
                            <Col xs={6} className="text-end">
                                {memberCard.subscriptionModels.flatMap(model => model.subscriptions.map(sub => sub)).filter(sub => sub.isActive).length}
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            )}
        </>
    );
};
