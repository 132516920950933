import { Button, Modal } from "react-bootstrap"
import { postRequest } from "../../../../../utils/apiRequest"
import { useOutletContext } from "react-router-dom"
import { useState } from "react";
import { Loader } from "../../../../../components/loader";

export const DeleteConfirmModal = ({ showDeleteConfirmModal, setShowDeleteConfirmModal, membercard, token, refetch }) => {
    
    const [showToast, setShowToast] = useOutletContext();
    const [isLoading, setIsLoading] = useState(false);

    const handleDelete = async () => {
        setIsLoading(true);
        const response = await postRequest('/auth/membercard/delete', token, {id: membercard.id});

        if (response.error) {
            setShowToast({
                toastStatus: true,
                toastMessage: response.error,
                toastAppearance: 'danger'
            });
        }
        else {
            setShowToast({
                toastStatus: true,
                toastMessage: 'Membercard erfolgreich gelöscht. 🥳',
                toastAppearance: 'success'
            });
            setShowDeleteConfirmModal(false);
            refetch();
        }
        setIsLoading(false);
    }

    if (isLoading) {
        return <Loader isActive={true}></Loader>
    }
    
    return (
        <Modal
            show={showDeleteConfirmModal}
            onHide={() => setShowDeleteConfirmModal(false)}
            centered
        >
            <Modal.Header>
                <Modal.Title>{membercard.partner.name} Membercard löschen</Modal.Title>
            </Modal.Header>
                <Modal.Body>
                    <p className="m-0">Möchtest du die {membercard.partner.name} Membercard wirklich löschen?</p>
                </Modal.Body>
            <Modal.Footer className="p-3">
                <Button className="btn btn-danger" onClick={() => setShowDeleteConfirmModal(false)}>Abbrechen</Button>
                <Button className="btn btn-success" onClick={handleDelete}>Löschen</Button>
            </Modal.Footer>
        </Modal>
    )
}