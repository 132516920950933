import { useQuery } from "@tanstack/react-query"
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap"
import { getRequest } from "../../utils/apiRequest"
import { useRecoilValue } from "recoil";
import { userState } from "../../utils/atom";
import { Loader } from "../loader";
import '../../styles/card-list/card-list.scss'
import { Link, useNavigate } from "react-router-dom";
import { formatDateTimeToDDMMYY, formatDateTimeToHHMM } from "../../utils/formatDateTime";
import { PeopleFill } from 'react-bootstrap-icons'
import { useState } from "react";

export const UpcomingCourses = () => {

    const MAX_COURSES_TO_SHOW = 4;
    const { token } = useRecoilValue(userState);
    const navigate = useNavigate();
    const [hidefull, setHidefull] = useState(false);

    const upcomingCoursesQuery = useQuery({
        queryKey: ['upcomingcoursesuser', hidefull],
        queryFn: () => getRequest(`/auth/course/upcoming-user?hidefull=${hidefull}`, token)
    });
    const { data } = upcomingCoursesQuery;

    return (
        <Loader isActive={upcomingCoursesQuery.isFetching || upcomingCoursesQuery.isLoading}>
            {data &&
                <Container className="border-bottom p-3">
                    <Row>
                        <Col xs={12}>
                            <h5
                                onClick={() => navigate('/all-courses')}
                                style={{cursor: 'pointer'}}
                            >
                                Demnächst stattfindende Kurse
                            </h5>
                        </Col>
                        <Col xs={12}>
                            <Row className="align-items-start justify-content-start">
                                <Col xs={12} style={{width: 'fit-content'}}>   
                                    <Form>
                                        <Form.Group className="d-flex flex-row">
                                            <Form.Label className="me-3">
                                                Volle Kurse ausblenden?
                                            </Form.Label>
                                            <Form.Switch 
                                                id="hidefull"
                                                checked={hidefull}
                                                onChange={() => setHidefull(!hidefull)}
                                            />
                                        </Form.Group>
                                    </Form>
                                </Col> 
                            </Row>
                        </Col>
                    </Row>

                    {data.courses.length < 1 &&
                        <Row>
                            <Col className="mt-3">
                                In nächster Zeit finden keine weiteren Kurse statt.
                            </Col>
                        </Row>
                    }
                    {data.courses.map((course, index) => {
                        if (index < MAX_COURSES_TO_SHOW) {
                            return (
                                <Row key={course.id}>
                                    <Col className="mt-3 mb-3">
                                        <Card className={`${course.performanceLevel === 4 && 'border-warning'}`}>
                                            <Card.Header
                                                className="hoverable-title p-3"
                                                onClick={() => navigate(`/course/${course.id}`)}
                                            >
                                                <Row>
                                                    <Col xs={8}>
                                                        {course.title}
                                                    </Col>
                                                    <Col  xs={4} className="text-end">
                                                        {course.participants} | {course.maxParticipants} <PeopleFill />
                                                    </Col>
                                                </Row>
                                            </Card.Header>
                                            <Card.Body>
                                                <Row>
                                                    <Col>
                                                        {course.locationName}
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col>
                                                        {formatDateTimeToDDMMYY(course.date)}, {formatDateTimeToHHMM(course.date)} Uhr
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                            <Card.Footer className="p-3">
                                                <Button
                                                    onClick={() => navigate(`/course/${course.id}`)}
                                                >
                                                    Jetzt teilnehmen
                                                </Button>
                                            </Card.Footer>
                                        </Card>
                                    </Col>
                                </Row>
                            )
                        }
                    })}
                    {data.courses.length > MAX_COURSES_TO_SHOW &&
                        <Row>
                            <Col>
                                <Link to="/all-courses">
                                    {`Alle ${data.courses.length} Kurse ansehen`}
                                </Link>
                            </Col>
                        </Row>
                    }
                </Container>
            }
        </Loader>
    )
}